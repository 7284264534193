const firebaseConfig = {
	apiKey: "AIzaSyA5MWHfMO-NBstctMOpmLXaWLPtVm1-YGo",
	authDomain: "vlogpost-a1fbc.firebaseapp.com",
	databaseURL: "https://vlogpost-a1fbc-default-rtdb.firebaseio.com",
	projectId: "vlogpost-a1fbc",
	storageBucket: "vlogpost-a1fbc.appspot.com",
	messagingSenderId: "519275078169",
	appId: "1:519275078169:web:59bb6f61dab40c09b18088",
	measurementId: "G-8BGME9M5C2"
  };
  
  export default firebaseConfig;