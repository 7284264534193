// src/components/LandingPage.js
import React from 'react';

const LandingPage = () => {
  return (
    <div className="landing-container">
      <h2>Welcome to VlogPost</h2>
      <p>
      VlogPost allows you to generate blog posts and summaries from YouTube videos. Simply enter the URL of a YouTube video, and we will generate a summary or blog post based on the video content.
      </p>
      <p>
      Are you tired of spending hours watching long YouTube videos only to extract a few key points? With VlogPost, you can get concise and valuable information in just minutes.
      </p>
      <p>
      Login to get started!
      </p>
      <p>
      For any questions, you can send me an email: support@vlog-post.com
      We do not store any user data except your email, and we will never share it with anyone.
      </p>
      <div className="pricing-container">
        <div className="pricing-card">
          <h1>Free Tier</h1>
          <p>Usage limits: 50K tokens / month</p>
          <p>Price: 0 $ USD / month</p>
        </div>
        <div className="pricing-card">
          <h1>Pro Tier - Maybe soon!</h1>
          <p>For now we limit usage for 50K tokens per user per month, in the future we might enable pro tier with higher usage limit</p>
          <p>If you have any feedback drop us an email and we will increase your limit :)</p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
