// src/components/Auth.js
import React from 'react';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const Auth = ({ onLogin }) => {
  const [user] = useAuthState(auth);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then(() => onLogin());
  };

  const signOutUser = () => {
    signOut(auth);
  };

  return (
    <div>
      {user ? (
        <div>
          <p>Hello, {user.displayName}</p>
          <button onClick={signOutUser}>Sign Out</button>
        </div>
      ) : (
        <button onClick={signInWithGoogle} className='sign-in-button'>Sign in with Google</button>
      )}
    </div>
  );
};

export default Auth;
