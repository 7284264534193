import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import URLInput from './components/URLInput';
import UserProfile from './components/UserProfile';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import 'github-markdown-css/github-markdown.css';
import './index.css';

const App = () => {
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  const [isLoggedIn, setIsLoggedIn] = useState(!!user);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <Router>
      <div className="app">
        <Header isLoggedIn={isLoggedIn} onLogin={handleLogin} />
        <main className="main">
          <Routes>
            <Route path="/" element={user ? <URLInput /> : <LandingPage />} />
            <Route path="/profile" element={user ? <UserProfile /> : <Navigate to="/" />} />
          </Routes>
        </main>
        <footer>
          <p>The service is provided on an 'as-is' basis. The creators of the service will not be held liable for any damage caused to you or any third party through its use. By using this service, you agree to these terms.</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;
