import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase'; // Import auth from your Firebase configuration
import Auth from './Auth';
import '../App.css'; // Ensure App.css is imported

const Header = ({ isLoggedIn, onLogin }) => {
  const user = auth.currentUser;
  return (
    <header>
      <h1><Link to="/">VlogPost</Link></h1>
      <nav>
        <ul>
          {isLoggedIn && user? (
            <>
              <li><Link to="/profile" className='sign-in-button'>Profile</Link></li>
              <li className='sign-in-button'>Hello, {auth.currentUser.displayName}</li>
              <li><button onClick={() => auth.signOut()} className='sign-in-button'>Logout</button></li>
            </>
          ) : (
            <li><Auth onLogin={onLogin} /></li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
