import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';

const UserProfile = () => {
    const [userProfile, setUserProfile] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('No user is signed in');
                }

                console.log("Fetching ID token..."); // Debug log
                const token = await user.getIdToken();
                console.log("ID token fetched:", token); // Debug log

                const response = await fetch('https://vlogpost-im5cabzmbq-ue.a.run.app/getUserProfile', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log("User profile data:", data); // Debug log
                setUserProfile(data);
            } catch (error) {
                console.error("Error fetching user profile:", error); // Debug log
                setError(error.message);
            }
        };

        fetchUserProfile();
    }, []);

    // const handleUpgrade = async () => {
    //     try {
    //         const auth = getAuth();
    //         const user = auth.currentUser;
    //         if (!user) {
    //             throw new Error('No user is signed in');
    //         }

    //         console.log("Fetching ID token for upgrade..."); // Debug log
    //         const token = await user.getIdToken();
    //         console.log("ID token fetched for upgrade:", token); // Debug log

    //         const response = await fetch('https://vlogpost-im5cabzmbq-ue.a.run.app/updateUserTier', {
    //             method: 'POST',
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 newTier: 'paid',
    //                 newTokenLimit: 20000
    //             })
    //         });

    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         console.log("User tier upgraded successfully"); // Debug log
    //         setUserProfile(prevState => ({
    //             ...prevState,
    //             Tier: 'paid',
    //             TokenLimit: 20000
    //         }));
    //     } catch (error) {
    //         console.error("Error upgrading user tier:", error); // Debug log
    //         setError(error.message);
    //     }
    // };

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!userProfile) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <p>Email: {userProfile.Email}</p>
            <p>Name: {userProfile.Name}</p>
            <p>Current Token Usage: {userProfile.TokensUsed}</p>
            <p>Token Limit: {userProfile.TokenLimit}</p>
            <p>Tier: {userProfile.Tier}</p>
            {/* <button onClick={handleUpgrade}>Upgrade to Paid Tier</button> */}
        </div>
    );
};

export default UserProfile;
