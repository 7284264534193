import React, { useState } from 'react';
import { marked } from 'marked';
import 'github-markdown-css/github-markdown.css';
import { getAuth } from 'firebase/auth';
import '../App.css'; // Assuming you have this CSS file for styling

const URLInput = () => {
  const [url, setUrl] = useState('');
  const [post, setPost] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('');
  const [transcript, setTranscript] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setStatus('Authenticating user...');

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is signed in');
      }

      const token = await user.getIdToken();
      console.log('Retrieved token:', token);

      setStatus('Fetching data from YouTube...');

      const response = await fetch(`https://vlogpost-im5cabzmbq-ue.a.run.app/getPost`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ url, transcript }),
      });

      if (!response.ok) {
        if (response.status === 403) {
          throw new Error('Token limit exceeded. Please upgrade your plan or wait until the next billing cycle.');
        }
        if (response.status === 429) {
          throw new Error('Request too large for the current token limit.');
        }
        throw new Error('Network response was not ok');
      }

      setStatus('Generating blog post...');

      const data = await response.text();
      setPost(data);
      setStatus('Blog post generated successfully!');
    } catch (error) {
      setError(error.message);
      setStatus('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="url-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter YouTube URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Generating...' : 'Generate Blog Post'}
        </button>
        <div>
          <label>
            <input
              type="checkbox"
              checked={transcript}
              onChange={(e) => setTranscript(e.target.checked)}
            />
            Provide full video transcript
          </label>
        </div>
      </form>
      {loading && (
        <div>
          <div className="spinner"></div>
          <p className="status-message">{status}</p>
        </div>
      )}
      {error && <p className="error">Error: {error}</p>}
      {post && (
        <div className="post-container">
          <div dangerouslySetInnerHTML={{ __html: marked(post) }} />
        </div>
      )}
    </div>
  );
};

export default URLInput;
